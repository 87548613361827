import './Description.scss'
import Container from "../BlockElement/Container/Container";
import Header from '../BlockElement/Header/Header';
import { IDescriptionElement } from '../../types/IDescriptionElement';
import Paragraph from '../TextElement/Paragraph/Paragraph';

export default function Description({title, text, color}: IDescriptionElement) {
    return (
        <Container className={`description-container  ${color === 'blue' ? 'text-blue' : 'text-white'}`}>
        <Container className='text-block'>
          <Header className='description-header'>{title}</Header>
          <Paragraph htmlFor='' items={[]}>{text}</Paragraph>
        </Container>
      </Container>
    );
  }