import { IPriceSection } from "../types/IPriceSection";

export const pricingSections: IPriceSection[] = [
  {
    title: "Ortodoncja",
    items: [
      { name: "Konsultacja ", price: "150 zł" },
      {
        name: "Pakiet przygotowania do leczenia (konsultacja ortodontyczna, pantomogram, cefalo, skany) ",
        price: "500 zł",
      },
      { name: "Skanowanie 3D zębów ", price: "200 zł" },
      {
        name: "Aparat stały samoligaturujący metalowy ( 1 łuk ) ",
        price: "2500 zł",
      },
      {
        name: "Aparat stały samoligaturujący estetyczny ( 1 łuk )",
        price: "3500 zł",
      },
      { name: "Płytka Hawleya ", price: "950 zł" },
      { name: "Płytka Nance ", price: "1050 zł" },
      { name: "Płytka Schwarza ( 1 łuk ) ", price: "900 zł" },
      { name: "Twin Block ", price: "1800 zł" },
      { name: "Hyrax, Haas ", price: "1200 zł" },
      { name: "Mini implanty ortodontyczne ", price: "600 – 800 zł" },
      { name: "Retainer stały ( 1 łuk ) ", price: "400 zł" },
      { name: "Zdjęcie aparatu stałego (łuk) ", price: "150 zł" },
      { name: "Szyna retencyjna ", price: "400 zł" },
      { name: "Wizyty kontrolne - aparat ruchomy ", price: "150 zł" },
      { name: "Wizyty kontrolne - aparat stały", price: "250 zł" },
      { name: "Wizyty kontrolne - po leczeniu", price: "150 zł" },
    ],
  },
  {
    title: "Profilaktyka",
    items: [
      {
        name: "Scaling- usuwanie płytki bakteryjnej i kamienia naddziąsłowego",
        price: "150 zł",
      },
      { name: "Piaskowanie usuwanie osadów", price: "150 zł" },
      {
        name: "Fluoryzacja piankowa na łyżkach indywidualnych",
        price: "150 zł",
      },
      {
        name: "Pakiet SuperClean (scaling, piaskowanie, polerowanie, fluoryzacja)",
        price: "350 zł",
      },
    ],
  },
  {
    title: "Stomatologia zachowawcza",
    items: [
      { name: "Znieczulenia", price: "50 zł" },
      {
        name: "Wypełnienie kompozytowe w zębie stałym",
        price: "250 – 450 zł",
      },
      { name: "Włókno szklane (1szt)", price: "250 zł" },
      { name: "Opatrunek leczniczy tymczasowy", price: "100 zł" },
      { name: "System ICON (1 ząb)", price: "250 zł" },
      { name: "Laser biostymulacyjny", price: "100 zł" },
    ],
  },
  {
    title: "Endodoncja",
    items: [
      {
        name: "Opracowanie i ostateczne wypełnienie zęba jednokanałowego",
        price: "350 zł",
      },
      {
        name: "Opracowanie i ostateczne wypełnienie 1 kanału w zębie wielokanałowego",
        price: "250 zł",
      },
      {
        name: "Re-endo (powtórne leczenie kanałowe) w zębach jednokanałowych",
        price: "400 zł",
      },
      {
        name: "Re-endo (powtórne leczenie kanałowe) 1 kanału w zębach wielokanałowych",
        price: "300 zł",
      },
    ],
  },
  {
    title: "Stomatologia dziecięca",
    items: [
      { name: "Wypełnienie kompozytowe w zębie mlecznym ", price: "200 zł" },
      { name: "Amputacja zęba mlecznego", price: "250 zł" },
      { name: "Wizyta adaptacyjna", price: "100 zł" },
      { name: "Opatrunek leczniczy w zębie mlecznym", price: "100 zł" },
      { name: "Wypełnienie kompozytowe w zębie mlecznym", price: "200 zł" },
      { name: "Lakowanie bruzd w zębie stałym", price: "100 zł" },
      { name: "Fluoryzacja - lakier fluorowy (1 łuk)", price: "100 zł" },
      { name: "Usunięcie zęba mlecznego", price: "200 zł" },
    ],
  },
  {
    title: "Chirurgia stomatologiczna",
    items: [
      { name: "Konsultacja chirurgiczna", price: "150 zł" },
      { name: "Usunięcie zęba mlecznego", price: "200 zł" },
      { name: "Usunięcie zęba stałego", price: "250-300 zł" },
      { name: "Usunięcie 8-ki górnej", price: "400-500 zł" },
      { name: "Usunięcie 8-ki dolnej", price: "500-600 zł" },
      { name: "Usunięcie zęba zatrzymanego", price: "800-1000 zł" },
    ],
  },
  {
    title: "Implantologia",
    items: [
      { name: "Konsultacja implantologiczna", price: "200 zł" },
      { name: "Wszczepienie implantu", price: "Od 2000 zł" },
    ],
  },
  {
    title: "Protetyka",
    items: [
      { name: "Konsutacja protetyczna", price: "150 zł" },
      {
        name: "Korona porcelanowa na podbudowie z metalu",
        price: "1200 zł",
      },
      { name: "Korona pełnoceramiczna", price: "2000 zł" },
      { name: "Wkład koronowo-korzeniowy lany", price: "Od 450 zł" },
      { name: "Licówka kompozytowa", price: "900 zł" },
      { name: "Licówka porcelanowa", price: "2000 zł" },
      { name: "Inlay/Onlay kompozytowy", price: "900 zł" },
      { name: "Inlay/Onlay ceramiczny (e-max)", price: "1200 zł" },
      { name: "Proteza akrylowa osiadająca", price: "1300 zł" },
      { name: "Proteza szkieletowa", price: "2400 zł" },
    ],
  },
  {
    title: "Wybielanie",
    items: [
      {
        name: "Wybielanie metodą nakładkową (zestaw szyn + 3 strzykawka żelu wybielającego oraz 1 strzykawka odwrażliwiacza Ultra ez)",
        price: "1000 zł",
      },
      { name: "Żel wybielający (1 strzykawka)", price: "100 zł" },
    ],
  },
  {
    title: "RTG",
    items: [
      { name: "Zdjęcie zębowe (małe)", price: "50 zł" },
      { name: "Cefalometria", price: "90 zł" },
      { name: "Pantomogram", price: "90 zł" },
    ],
  },
];
