import { IDepartment } from "../types/IDepartment";

export const departments: IDepartment[] = [
  {
    title: "Ortodoncja",
    services: [
      "Ortodoncja dorosłych",
      "Leczenie ortodontyczne aparatem samoligaturującym",
      "Ortodoncja dzieci i młodzieży",
      "Leczenie ortodontyczne aparatami ruchomymi",
    ],
  },
  {
    title: "Leczenie nakładkowe",
    services: ["Leczenie nakładkowe"],
  },
  {
    title: "Protetyka",
    services: ["Korony i mosty", "Protezy całkowite i częściowe", "Licówki"],
  },
  {
    title: "Chirurgia",
    services: [
      "Usuwanie zębów",
      "Zabiegi na tkankach miękkich",
      "Leczenie urazów",
    ],
  },
  {
    title: "Implantologia",
    services: ["Implantologia"],
  },

  {
    title: "Stomatologia Zachowawcza",
    services: ["Stomatologia zachowawcza"],
  },
  {
    title: "Endodoncja",
    services: ["Endodoncja"],
  },
  {
    title: "RTG",
    services: ["Radiodiagnostyka stomatologiczna"],
  },
];
