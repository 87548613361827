import "./Department.scss";
import Container from "../BlockElement/Container/Container";
import { IDepartment } from "../../types/IDepartment";
import Service from "../Service/Service";

export default function Department({ title, services }: IDepartment) {
  return (
    <Container className="department">
      <h2>{title}</h2>
      <Container className="department-services">
        {services.map((service, index) => (
          <Service key={index} title={service} />
        ))}
      </Container>
    </Container>
  );
}
