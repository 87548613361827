import Container from '../BlockElement/Container/Container';
import Section from '../BlockElement/Section/Section';
import { departments } from '../../utils/DepartmentsData';
import './Services.scss';
import Department from '../Department/Department';
import { IDepartment } from '../../types/IDepartment';

export default function Services(){
    return (
    <Section id="uslugi" className='services-section'>
    <Container className='services-container'>
    {departments.map((department: IDepartment, index: number) => ( 
                    <Department key={index} title={department.title} services={department.services} />
                ))}
    </Container>
    </Section>
);
}