import React, { Fragment } from "react";
import "./Page.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Navbar from "../Navbar/Navbar";
import Footbar from "../Footbar/Footbar";
import Main from "../../BlockElement/Main/Main";

export default function Page({ children, className }: IStructureElement & { className?: string }) {
    return(
        <Fragment>
            <Navbar className={className === "prices-page" ? "navbar--transparent" : ""} />
            <Main className={className}>{children}</Main>
            <Footbar />
        </Fragment>
    );
}