import React from "react";
import "./Home.scss";
import Page from "../../components/StructureElement/Page/Page";
import Hero from "../../components/Hero/Hero";
import AboutUs from "../../components/AboutUs/AboutUs";
import Team from "../../components/Team/Team";
import Services from "../../components/Services/Services";
import Ortho from "../../components/Ortho/Ortho";
import Equipment from "../../components/Equipment/Equipment";
import Opinions from "../../components/Opinions/Opinions";

export default function Home(){
    return(
        <Page>
            <Hero />
            <AboutUs />
            {/* <Team /> */}
            <Services />
            <Ortho />
            {/* <Equipment />
            <Opinions /> */}
        </Page>
    );
}