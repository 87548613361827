import Section from "../BlockElement/Section/Section";
import "./Ortho.scss";
import Button from "../FormElement/Button/Button";
import Container from "../BlockElement/Container/Container";
import { orthoContent } from "../../utils/OrthoContent";
import Description from "../Description/Description";
import orthoImage from "../../assets/ortho.png";

export default function Ortho() {
  return (
    <Section>
      <Container className="ortho__container">
        <Container className="ortho__background">
          <Description
            title={orthoContent.title}
            text={orthoContent.text}
            color={orthoContent.color}
          />
          <Container className="smaller__button">
            <a href="/uslugi/leczenie_nakladkowe">
              <Button className="white small">Poznaj szczegóły</Button>
            </a>
          </Container>
          <Container className="ortho__photo">
            <img src={orthoImage} alt="Ortho" className="ortho__image" />
          </Container>
        </Container>
      </Container>
    </Section>
  );
}
