import React, { useEffect } from "react";
import "./Prices.scss";
import Page from "../../components/StructureElement/Page/Page";
import Container from "../../components/BlockElement/Container/Container";
import { pricingSections } from "../../utils/PriceContent";

export default function Prices() {
    useEffect(() => {
        document.body.classList.add("prices-background");

        return () => {
            document.body.classList.remove("prices-background");
        };
    }, []);

    return (
        <Page>
            <Container className="prices-content">
                
            <h1 className="prices-title">Cennik</h1>
                
                {pricingSections.map((section, index) => (
                    <div key={index} className="pricing-section">
                        <h2 className="pricing-section-title">{section.title}</h2>
                        <ul className="pricing-section-list">
                            {section.items.map((item, itemIndex) => (
                                <li key={itemIndex} className="pricing-item">
                                    <span className="pricing-item-name">{item.name}</span>
                                    <span className="pricing-item-price">{item.price}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </Container>
        </Page>
    );
}
